@import '~govuk_frontend_toolkit/stylesheets/design-patterns/buttons';
@import '../settings/colours';

// Buttons
// ==========================================================================

.button {
  @include button($button-colour);
  padding-bottom: 0.526315em;

  @include media(mobile) {
    width: 100%;
    text-align: center;
  }
}

// Fix unwanted button padding in Firefox
.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button:focus {
  outline: 3px solid $focus-colour;
}

.button[disabled='disabled']:focus {
  outline: none;
}

// modifiers
.button--secondary {
  @include button($grey-2);
  padding-bottom: 0.526315em;
}

.button--destructive {
  @include button($red);
  padding-bottom: 0.526315em;
}

.button--link {
  background: transparent;
  border: 0;
  box-shadow: none;
  color: $link-colour;
  cursor: pointer;
  padding: 0.4em 0;
  text-align: left;
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    color: $link-colour;
    top: 0;
  }

  &:hover {
    color: $link-hover-colour;
  }
}

.button--compact {
  padding: 0;
}
