@import '../tools/functions/typography';

h1 {
  @include bold-font(48);
}

h2 {
  @include bold-font(24);
}

h3 {
  @include bold-font(20);
}

h4 {
  @include bold-font(16);
}

h5 {
  @include core-font(16);
}
