@import '../../settings/colours';
@import '../../tools/mixins/layout';

.phase-banner {
  width: 100%;
  display: table;
  font-size: 0.85em;
  padding-top: $baseline-grid-unit * 2;
  padding-bottom: $baseline-grid-unit * 2;
  border-bottom: 1px solid $grey-2;
}

// TODO: remove qualifier
span.phase-banner__message {
  display: inline-block;
}
