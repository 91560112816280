@import '../tools/mixins/addons';

.u-visually-hidden {
  @include visually-hidden;
}

.u-hidden {
  display: none !important;
}

.u-js-hidden {
  .js-enabled & {
    display: none;
  }
}

.u-no-js-hidden {
  .no-js & {
    display: none !important;
  }
}

.u-print-hide {
  @media print {
    display: none !important;
  }
}

.u-highlight {
  background-color: $yellow-25;
}

.u-clearfix {
  @include clearfix;
}

.u-float-left {
  @include media-query(tablet) {
    float: left !important;
  }
}

.u-float-right {
  @include media-query(tablet) {
    float: right !important;
  }
}

.u-max-half {
  max-width: 50%;
}

.u-block {
  display: block;
}

.u-loading {
  &::before {
    animation: growRight 3s;
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    border-top: 2px solid $light-blue;
    border-bottom: 1px solid rgba($white, 0.6);
  }
}

.u-icon-print {
  background: url('../../images/icon--print.png') no-repeat 0 50%;
  border: 0;
  color: $link-colour;
  cursor: pointer;
  font-size: inherit;
  padding: 0 0 0 28px;
  text-decoration: underline;

  &:hover {
    color: $link-hover-colour;
  }

  &:active {
    color: $link-active-colour;
  }

  &:visited {
    color: $link-visited-colour;
  }

  @include device-pixel-ratio {
    background-image: url('../../images/icon--print@2x.png');
    background-size: 16px 18px;
  }
}

@keyframes growRight {
  0% {
    right: 100%;
  }
  20% {
    right: 60%;
  }
  100% {
    right: 0;
  }
}
