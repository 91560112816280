@import '../settings/colours';

details {
  clear: both;
  display: block;

  summary {
    @include core-font(20);
    color: $link-colour;
    cursor: pointer;
    display: inline-block;
    margin-bottom: em(5);
    position: relative;

    &:hover {
      color: $link-hover-colour;
    }

    &:focus {
      outline: 3px solid $focus-colour;
    }

    // Show the arrow on summary elements in Firefox - _details.scss
    // The current Firefox implementation uses display: list-item to show the arrow marker.
    // We want to use display: inline-block to shrink-wrap the focus outline around the text.
    // This will turn off inline-block for Firefox that’s not using the polyfill only.
    // @-moz-document is going away: https://bugzilla.mozilla.org/show_bug.cgi?id=1035091 .
    // Hopefully they’ll fix <summary> first but if not it’ll fall back to no arrow:
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1270163
    // If Mozilla add display: revert and remove list-item from summary then this will fall through.
    @-moz-document regexp(".*") {
      &:not([tabindex]) {
        // Allow duplicate properties, override the summary display property
        // sass-lint:disable no-duplicate-properties
        display: list-item;
        display: revert;
      }
    }
  }
}

// Underline only summary text (not the arrow)
.details__summary {
  text-decoration: underline;

  .details--inline & {
    text-decoration: none;
  }
}

// Match fallback arrow spacing with -webkit default
.details__arrow {
  font-style: normal;
  margin-right: 0.35em;

  .details--inline & {
    display: none;
  }
}

.details__cta {
  color: $link-colour;
  cursor: pointer;
  text-decoration: underline;
}

.details--inline {
  summary {
    color: $black;
    cursor: default;

    &::-webkit-details-marker {
      display: none;
    }

    &:hover {
      color: $black;
    }

    &:focus {
      outline: none;

      .details__cta {
        outline: 3px solid $focus-colour;
      }
    }
  }
}
