@import '../settings/colours';
@import '../settings/layout';
@import '../tools/mixins/typography';

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  caption {
    @include core-font(24);
    margin-bottom: $default-spacing-unit;
    text-align: left;
  }

  th,
  td {
    @include core-font(16);
    border-bottom: 1px solid $grey-2;
    padding: ($baseline-grid-unit * 2);
    vertical-align: top;
  }

  th {
    font-weight: 700;
    text-align: left;

    &[align='right'] {
      text-align: right;
    }
  }
}

.table__cell--plain {
  border-bottom: 0;
}
