@import '../../settings/colours';
@import '../../tools/mixins/layout';

.notification-banner {
  background-color: $light-blue-25;
}

.notification-banner__inner {
  @include site-width-container;
  padding: $default-spacing-unit 0;
  margin-top: 0;
  margin-bottom: 0;
}
