@import '../tools/mixins/layout';

.l-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: $default-spacing-unit;
  padding-right: $default-spacing-unit;
  max-width: $site-width;

  @include media-query($min-width: $site-width) {
    padding-left: 0;
    padding-right: 0;
  }
}

.l-reading-width {
  max-width: 32em;
}

.column-two-quarters,
.column-three-quarters {
  > h1,
  > h2 {
    &:first-child {
      @include media-query(tablet) {
        margin-top: 0;
      }
    }
  }
}

// TODO: Find a better way to format basic html elements...
.l-markdown {
  h1 {
    @include bold-font(36);
  }

  h2 {
    @include bold-font(24);
  }

  h3 {
    @include bold-font(20);
  }

  h4,
  h5 {
    @include bold-font(16);
  }

  * {
    margin: 0;

    & + {
      h1,
      h2,
      h3,
      h4,
      h5 {
        margin-top: $default-spacing-unit * 2;
      }
    }
  }

  * + * {
    margin-top: $default-spacing-unit;
  }

  ul,
  ol {
    padding-left: 1em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1.5em;

    li {
      padding-left: 0;
    }
  }
}
