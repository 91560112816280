@import '../../settings/layout';
@import './conditionals';
@import './addons';

/// Grid column
/// -----------
/// Creates a cross browser grid column with a standardised gutter between the
/// columns.
///
/// @prop {number} width - a fraction of what width to create column
/// @prop {string} full-width [tablet] - at what point to break to full width
/// @prop {string} float [left] - which way to float column (left/right)
///
/// @example scss - Column one quarter of container
///   div {
///     @include grid-column(1 / 4);
///   }
///
/// @example scss - Column one half of container
///   div {
///     @include grid-column(1 / 2);
///   }
///
/// @example scss - Forcing column to be full width at desktop
///   div {
///     @include grid-column(1 / 2, $full-width: desktop);
///   }
///
/// @example scss - Float column to the right
///   div {
///     @include grid-column(1 / 2, $float: right);
///   }
@mixin grid-column($width, $full-width: desktop, $float: left) {
  display: block;
  padding: 0 ($gutter / 2);

  @include media-query($full-width) {
    float: $float;
    width: percentage($width);
  }
}

@mixin grid-row {
  @include clearfix;
  margin: 0 (-($gutter / 2));

  @include media-query(desktop) {
    .page-section &,
    .local-header & {
      > * {
        margin-top: 0;
      }
    }
  }
}

@mixin site-width-container {
  margin: 0 ($gutter / 2);
  max-width: $site-width;

  @include media-query(desktop) {
    margin: 0 $gutter;
  }

  @include media-query(
    $min-width: (
      $site-width + $gutter * 2,
    )
  ) {
    margin: 0 auto;
  }
}

@mixin reading-width {
  max-width: $measure;
}
