@import '../settings/colours';
@import '../settings/layout';

.list-unstyled,
.list-inline {
  list-style: none;
  padding-left: 0;
}

.list-inline li {
  margin-right: 1em;
  display: inline-block;
}

.list-lined li {
  border-bottom: 1px solid $border-colour;
  padding: ($gutter / 2) ($gutter / 2) ($gutter / 2) 0;

  &:first-child {
    border-top: 1px solid $border-colour;
  }
}

.list-disc {
  list-style: disc outside;
  padding-left: 1em;
}
