@import '../settings';
@import '../tools/mixins/layout';

// View assemblies
// Define how components stack in views

body {
  * + * {
    margin-top: $default-spacing-unit * 2;
    margin-bottom: 0;
  }
}

summary,
script,
noscript,
input[type='hidden'],
.skip-links,
.notification-banner,
.global-header,
.main-content {
  & + * {
    margin-top: 0;
  }
}

summary {
  & + * {
    margin-top: $default-spacing-unit;
  }
}

input {
  & + label {
    margin-top: 0;
  }
}

dt + dd,
li + li {
  margin-top: $baseline-grid-unit;
}

h2,
h3,
h4,
h5 {
  & + * {
    margin-top: $default-spacing-unit / 2;
  }
}

p + p {
  margin-top: $default-spacing-unit;
}

.c-meta-list + .list-unstyled {
  margin-top: $baseline-grid-unit;
}

.u-clearfix {
  > * {
    @include media-query(tablet) {
      margin-top: 0;
    }
  }
}
