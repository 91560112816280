@import '../settings/colours';
@import '../tools';

.local-header__back {
  margin-top: -$default-spacing-unit;
}

.local-header__heading {
  @include bold-font(48);

  + .local-header__subheading {
    margin-top: 0;
  }
}

.local-header__subheading {
  @include core-font(20);
  color: $grey-1;
}
