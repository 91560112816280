@import '../../settings/colours';
@import '../../settings/typography';
@import '../../tools/mixins';

.global-footer {
  background-color: $grey-3;
  border-top: 1px solid $grey-2;
  color: $grey-1;
  font-size: 0.9em;

  a {
    color: inherit;

    &:focus {
      color: $text-colour;
    }
  }

  @if $is-print {
    display: none;
  }
}

.global-footer__inner {
  @include site-width-container;
  padding-top: $default-spacing-unit * 4;
  padding-bottom: $default-spacing-unit * 4;
}

.global-footer__links,
.global-footer__copyright {
  @include media-query(tablet) {
    display: inline-block;
    margin-right: -4px;
  }
}

.global-footer__links {
  @include media-query(tablet) {
    width: 75%;
  }
}

.global-footer__open-government-licence {
  // TODO: Normalise units (em/rem)
  margin-top: 1em;

  @include media-query(tablet) {
    padding-left: 50px;
  }
}
