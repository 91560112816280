@import '../../settings/colours';
@import '../../tools/mixins';

.global-header {
  background-color: $black;
  color: $white;

  @if $is-print {
    color: $black;
  }
}

.global-header__inner {
  @include clearfix;
  @include site-width-container;
  padding: 10px 0;
}

.global-header__site-title {
  @include media-query(tablet) {
    float: left;
  }

  .phase-badge {
    margin-top: 6px;
  }
}

.global-header__crest {
  background: url('../../../images/dit-crest__white.png') 6px 0 no-repeat
    transparent;
  background-size: contain;
  border-left: 2px solid $white;
  display: inline-block;
  height: 30px;
  margin-right: 8px;
  width: 36px;
  vertical-align: text-bottom;

  @include device-pixel-ratio(2) {
    background-image: url('../../../images/dit-crest__white@2x.png');
    background-size: 28px 30px;
  }

  @if $is-print {
    display: none;
  }
}

.global-header__link {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  padding: 0;
  position: relative;
  margin: 0 ($baseline-grid-unit * 3) 0 0;

  &:hover,
  &:visited,
  &:focus {
    color: inherit;
  }
}

.global-header__logo {
  border-bottom: 1px solid transparent;
  margin-left: -4px;

  @if $is-print {
    margin-left: 0;
  }
}

.global-header__link:hover .global-header__logo {
  border-color: $white;
}

.global-header__site-name {
  @include visually-hidden;
}

.global-header__service-name {
  @include bold-font(36);
  color: inherit;
  line-height: 0.9;

  @include media-query(tablet) {
    line-height: 0.9;
    font-size: 30px;
  }
}
