@import '../../settings';

.main-content {
  position: relative;
  z-index: 1;
  background-color: $white;
  min-height: 60vh;
  flex-grow: 1;
}

.main-content__inner {
  @include site-width-container;
  padding-top: $default-spacing-unit * 2;
  padding-bottom: $default-spacing-unit * 4;

  @if $is-print {
    padding-top: 0;
  }
}
